<template>
    <div>
        <div class="card">
            <div class="card-header">
                <h1>Coucou</h1>
            </div>

            <div class="card-body">
            </div>
        </div>
    </div>
</template>

<script>
import {routesName} from "@/config/router";

export default {
    data() {
        return {
            routesName,
        };
    },
    mounted() {
    },
    methods: {
    },
}
</script>