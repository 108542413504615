import {client}  from "@/client";
import {DELETE_HTTP_ERROR, PUT_HTTP_ERROR} from "@/store/httpClient/types";
import {PUT_BABY_DRIVER} from "@/store/httpClient/BabyDriver/types";

export default {
    async login({ commit, state, dispatch }, credentials) {
        try {
            const { data: token } = await client.post(`/authentication_token`, credentials);

            await dispatch('putAccessToken', token.token);

            commit(DELETE_HTTP_ERROR, 'babyDriver', { root: true });
        } catch (exception) {
            commit(PUT_HTTP_ERROR, { formName: 'babyDriver', error: 'Identifiants invalide.' }, { root: true });

            throw exception;
        }
    },
    async getLoggedBabyDriver({ commit }) {
        try {
            const { data: babyDriver } = await client.get(`/me`);

            commit(PUT_BABY_DRIVER, babyDriver, { root: true });
            commit(DELETE_HTTP_ERROR, 'babyDriver', { root: true });
        } catch (exception) {
            commit(PUT_HTTP_ERROR, { formName: 'babyDriver', error: 'Une erreur est survenu.' }, { root: true });

            throw exception;
        }
    },

    resetBabyDriver({ commit }) {
        commit(PUT_BABY_DRIVER, {});
    },
};