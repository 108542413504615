import {client}  from "@/client";
import {DELETE_HTTP_ERROR, PUT_HTTP_ERROR} from "@/store/httpClient/types";
import {PUT_PLACES} from "@/store/httpClient/Place/types";

export default {
    async getPlaces({ commit }) {
        try {
            const { data: places } = await client.get(`/places`);

            commit(PUT_PLACES, places['hydra:member'], { root: true });
            commit(DELETE_HTTP_ERROR, 'places', { root: true });
        } catch (exception) {
            commit(PUT_HTTP_ERROR, { formName: 'places', error: 'Une erreur est survenu lors du chargement des places.' }, { root: true });

            throw exception;
        }
    },

    resetPlaces({ commit }) {
        commit(PUT_PLACES, []);
    },
};