import Vue from 'vue'

import store from './store'
import router from './config/router';

import App from './components/Template/App.vue'

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
