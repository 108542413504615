<template>
    <div class="card">
        <div class="card-header">
            <h3>Places</h3>
        </div>
        <div class="card-body">
            <ul class="">
                <li class="col-xs-4" v-for="place in places">
                    <span class="badge badge-primary"><strong>{{ place.name }}</strong></span> {{ place.attributes.length }} Attribut(s)
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {routesName} from "@/config/router";

export default {
    data() {
        return {
            routesName,
            isSubmitting: false,
        };
    },
    async mounted() {
        this.getPlaces();
    },
    methods: {
        ...mapActions([
            'getPlaces',
        ]),
    },
    computed: {
        ...mapGetters([
            'places',
        ]),
    },
    components: {
    },
}
</script>