import axios from "axios";

/**
 * Wrap axios client for substitution usage.
 */
class Client {
    apiKey;

    constructor() {
        this.axios = axios.create({
            baseURL: process.env.VUE_APP_API_BASE_URL,
            headers: {
                'content-type': 'application/ld+json',
            }
        });

        // If api key are set, use interceptor to append it to header.
        // We can't update the header after the creation, so we need to add this middleware.
        this.axios.interceptors.request.use((config) => {
            if (null !== this.apiKey && undefined !== this.apiKey) {
                config.headers['Authorization'] = `Bearer ${this.apiKey}`;
            }

            return config;
        });
    }

    configureApiKey(apiKey) {
        this.apiKey = apiKey;
    }

    get(uri, data = null, configuration = {}) {
        if (null !== data) {
            configuration.params = this.buildGetSearchParameters(data);
        }

        return this.axios.get(uri, configuration);
    }
    post(uri, data = {}, configuration = {}) {
        return this.axios.post(uri, data, configuration);
    }
    put(uri, data = {}, configuration = {}) {
        return this.axios.put(uri, data, configuration);
    }

    extractId(value) {
        if (true === value.hasOwnProperty('id')) {
            return value.id;
        }

        if (true === value.hasOwnProperty('@id')) {
            return value['@id'].split('/').pop();
        }

        return null;
    }
    extractErrors(errors, defaultError) {
        if (true === errors.hasOwnProperty('violations')) {
            let errorString = '';

            for (const violation of errors.violations) {
               errorString += ' ' + violation.message + '<br>';
            }

            return errorString;
        }

        if (true === errors.hasOwnProperty('hydra:description')) {
            return errors['hydra:description']
        }

        return defaultError;
    }
    buildGetSearchParameters(data) {
        const params = new URLSearchParams();

        for (const [name, value] of Object.entries(data)) {
            if (undefined === value) {
                continue;
            }

            if (Array.isArray(value)) {
                for (const item of value) {
                    params.append(name+'[]', item);
                }

                continue;
            }

            params.append(name, value);
        }

        return params;
    }
};

// Force usage of singleton for the client.
export const client = new Client();