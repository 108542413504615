<template>
    <div>
        <div class="card">
            <div class="card-header">
                <h1>Connexion</h1>
            </div>

            <div class="card-body">
                <div class="alert alert-danger" v-if="hasHttpFormError('babyDriver')">
                    <h3>Erreur</h3>

                    <p>{{ httpFormError('babyDriver') }}</p>
                </div>

                <form @submit.prevent="handleLogin">
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input id="email" type="email" class="form-control" v-model="credentials.email">
                    </div>
                    <div class="form-group">
                        <label for="password">Mot de passe</label>
                        <input id="password" type="password" class="form-control" v-model="credentials.password">
                    </div>

                    <button type="submit" class="btn btn-primary">Connexion</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {routesName} from "@/config/router";

export default {
    data() {
        return {
            routesName,
            isSubmitting: false,
            credentials: {
                email: null,
                password: null,
            }
        };
    },
    mounted() {
        if (true === this.babyDriver.hasOwnProperty('@id')) {
            this.redirect();
        }
    },
    methods: {
        ...mapActions([
            'login',
            'getPlaces',
        ]),
        handleLogin: async function (event) {
            try {
                await this.login(this.credentials);
            } catch (exception) {
                // An error happened and will be showed.
                return;
            }

            return this.redirect();
        },
        redirect: function() {
            if (true === this.babyDriver.roles.includes('ROLE_PLACE_MANAGER')) {
                return this.$router.push({ name: routesName.dashboard })
            }

            return this.$router.push({ name: routesName.index });
        }
    },
    computed: {
        ...mapGetters([
            'httpFormError',
            'hasHttpFormError',
            'babyDriver',
        ]),
    },
    components: {
    },
}
</script>