<template>
    <div class="card">
        <div class="card-header">
            <h3>Dashboard Admin</h3>
        </div>
        <div class="card-body">
            <ul>
                <li><router-link :to="{ name: routesName.listPlaces }">Places</router-link></li>
            </ul>
        </div>
    </div>
</template>

<script>
import {routesName} from "@/config/router";

export default {
    data() {
        return {
            routesName,
        };
    },
}
</script>