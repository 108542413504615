import Vue from 'vue';
import VueRouter from "vue-router";

import Login from '@/components/Page/Login';
import Index from '@/components/Page/Index';
import ListPlace from '@/components/Page/Admin/ListPlace';
import Dashboard from "@/components/Page/Admin/Dashboard";

Vue.use(VueRouter);

export const routesName = {
    index: 'index',
    login: 'login',
    dashboard: 'dashboard',
    listPlaces: 'listPlaces',
};

export const routes = [
    {
        name: routesName.login,
        path: '/login',
        component: Login,
    },
    {
        name: routesName.index,
        path: '/index',
        component: Index,
    },
    {
        name: routesName.dashboard,
        path: '/admin',
        component: Dashboard,
    },
    {
        name: routesName.listPlaces,
        path: '/admin/places',
        component: ListPlace,
    },
];

export default new VueRouter({ routes });
