<template>
    <div id="app" class="container">
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { routesName } from "@/config/router";

export default {
    name: 'App',
    async mounted() {
        await this.refreshAccessToken();

        if (false === this.babyDriver.hasOwnProperty('@id')) {
            return this.$router.push({ name: routesName.login })
        }
    },
    methods: {
        ...mapActions([
            'refreshAccessToken',
        ]),
    },
    computed: {
        ...mapGetters([
            'babyDriver',
        ])
    }
}
</script>
