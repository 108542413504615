import getters from './getters';
import mutations from './mutations';
import state from './state';

import BabyDriver from './BabyDriver';
import Place from './Place';

export default {
    namespaced: false,
    getters,
    mutations,
    state,
    modules: {
        BabyDriver,
        Place,
    }
};