import Vue from "vue";
import {DELETE_ALL_HTTP_ERROR, DELETE_HTTP_ERROR, PUT_HTTP_ERROR} from "./types";

export default {
    [PUT_HTTP_ERROR] (state, { formName, error }) {
        Vue.set(state.errors, formName, error);
    },
    [DELETE_HTTP_ERROR] (state, formName) {
        Vue.delete(state.errors, formName);
    },
    [DELETE_ALL_HTTP_ERROR] (state) {
        Vue.set(state, 'errors', {});
    },
};