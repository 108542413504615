import {PUT_ACCESS_TOKEN} from "./types";
import {client} from "@/client";

export default {
    async putAccessToken({ state, commit, dispatch }, accessToken) {
        commit(PUT_ACCESS_TOKEN, accessToken);

        client.configureApiKey(accessToken);

        window.localStorage.setItem('accessToken', accessToken);

        await dispatch('getLoggedBabyDriver');
    },
    async refreshAccessToken({ dispatch }) {
        const accessToken = window.localStorage.getItem('accessToken');

        if (null !== accessToken) {
            await dispatch('putAccessToken', accessToken);
        }
    }
};