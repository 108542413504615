import _ from 'lodash';

export default {
    hasHttpError: (state) => {
        return 0 !== _.size(state.errors);
    },
    hasHttpFormError: (state) => (formName) => {
        return state.errors.hasOwnProperty(formName);
    },
    httpFormError: (state) => (formName) => {
        return state.errors.hasOwnProperty(formName) ? state.errors[formName] : null;
    },
    httpFormErrors: (state) => {
        return state.errors;
    },
};